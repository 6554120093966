<template>
    <div class="Projectcation" style="margin-top:20px;">
        <div class="Project_button">
            <el-button type="primary" size="mini" @click="dialogVisible=true">添加批次</el-button>
        </div>
        <el-table 
            :data="tableData" 
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="time" label="立项时间"></el-table-column>
            <el-table-column prop="type" label="编号"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="people" label="备注"></el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="totalnum">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <div class="tcation_Popup">
            <el-dialog
                title="添加批次"
                :visible.sync="dialogVisible"
                width="580px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-form ref="ruleForm" :model="ruleForm" label-width="100px">
                    <el-form-item label="立项时间">
                        <el-input v-model="ruleForm.vipName"></el-input>
                    </el-form-item>
                    <el-form-item label="编号">
                        <el-input v-model="ruleForm.vipDate"></el-input>
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input v-model="ruleForm.vipDate"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model="ruleForm.vipDate"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="dialogVisible = false">确 定</el-button>
                    <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            pageSize:10,
            totalnum:0,
            dialogVisible:false,
            ruleForm:{},
            currentPage:1,
            tableData:[
                {
                    time:'2018',
                    name:'第一批次',
                    type:'20180001',
                    headName:'草稿',
                    people:'2018项目批次',
                },
                {
                    time:'2019',
                    name:'第二批次',
                    type:'20190002',
                    headName:'已发布',
                    people:'2019项目批次',
                },
                {
                    time:'2020',
                    name:'第三批次',
                    type:'20200003',
                    headName:'草稿',
                    people:'2020项目批次',
                },
            ],
        }
    },
    methods: {
        handleClose(done){
            console.og(done)
        },
        handleSizeChange(val){
            console.log(`当前页：${val}`)
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.tableData = this.newarrays[val - 1]
        },
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tcation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tcation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tcation_Popup .el-dialog__footer{
    text-align: center;
}
.tcation_Popup .el-form-item__content{
    width: 350px;
}
.tcation_Popup .el-dialog__body{
    padding: 30px 50px;
}
.Projectcation .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
</style>